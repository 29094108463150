// Useful mixin for before and after dividers
@mixin beforeafter {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 102%;
    overflow: hidden;
  }

  // Add divider to carousel
  body:not(.page-no-banner) .carouselSlides {
    position: relative;
    &:after {
        @include beforeafter;
        height: calc((110 / 1440) * 100vw);
        bottom: -1px;
        background-image: url($assets-path + "carousel-curve.svg");
    }
  }

  body.homepage .carouselSlides {
    position: relative;
    &:after {
        @include beforeafter;
        height: calc((110 / 1440) * 100vw);
        bottom: -1px;
        background-image: url($assets-path + "carousel-curve-home.svg");
    }
  }

  // Add divider to features
  .homeFeaturesWrapper {
    position: relative;
    &:after {
        @include beforeafter;
        height: calc((110 / 1440) * 100vw);
        bottom: -1px;
        background-image: url($assets-path + "carousel-curve-home.svg");
        transform: scaleX(-1);
    }
  }

// Add divider to footer
  .footerBox[class*="ewsletter"] {
    position: relative;
    padding-bottom: $spacer * 7;
    &:after {
        @include beforeafter;
        height: calc((70 / 1440) * 100vw);
        bottom: -1px;
        background-image: url($assets-path + "footer-curve.svg");
        transform: scaleY(-1);
    }
  }
// Adjust padding for overlap
.homeIntroWrapper {
    padding-bottom: $spacer * 10;
    .homeIntro strong {
        color: $_blue;
    }
}
.homeFeaturesWrapper {
    margin-top: $spacer * -5;
}

// Do fancy stuff on CTA donate button
.cta-button.donate {
    transform: scale(1);
    font-size: 1.115em;
    transition: all 0.4s cubic-bezier(0, 2, 0.43, 0.79);
    z-index: 2;
    &:before {
      content: "\f004";
      font-family: 'FontAwesome', icon;
      margin-right: 0.35em;
    }
    &:hover {
      transform: scale(1.1);
    }
  }

// Burder menu - Button
a.menuMainAlt {
  font-size: 1.125em;
  margin-left: 10px;
  z-index: 1;
}

// Nav - top Level - cursor hack
ul.topLevel li.level1.hasSubmenu > a { cursor: default; }

// Change home feed box one background colour
.homeFeedBox1 {
    background-color: $_lightblue;
    padding-top: $spacer;
}

// Tagline
.pageHeader .headerContent .tagline {
  font-size: 1.375em;
  margin: 0;
  line-height: 1;
  &:before {
    content: "\f095";
    font-family: $icon-font-family;
    margin-right: 0.5em;
    vertical-align: baseline;
    background-color: white;
    width: 1.5em;
    height: 1.5em;
    display: inline-block;
    text-align: center;
    border-radius: 2em;
    font-size: 1em;
    line-height: 1.65;
  }
}

// Footer stuff
.footerBox4 {
  text-align: right;
  .socialIcons {
    justify-content: flex-end;
    margin-right: -1em;
  }
  img {
    width: 200px;
    margin-top: $spacer;
  }
}

// Mob fixes
@media (max-width: map-get($breakpoints, lg)) {
    .carouselDetailWrapper .carouselSlideDetail {
      padding-bottom: $spacer * 4;
    }
    .homepage-quickgiving--container {
        padding-bottom: $spacer * 4;
    }
    .homefeaturecategory-homeboxquickgiving h2 {
        color: $grey-dark;
    }
  }
