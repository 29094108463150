//
// Environment
//
$warn: true;

//
// Config values
//

$platform-name: 'jamespagetuniversityhospitalch';


//
// Colours
//

$_blue: #005cb9;
$_lightblue: #a1daf8;
$_orange: #f18a00;
$_red: #b32572;
$_purple: #82368c;
$_green: #00953b;

$brand-primary: $_blue;
$brand-secondary: $_red;

$donate-colour: $brand-secondary !default;

// Create greyscale
$black:             #000 !default;
$grey-dark:         #292b2c !default;
$grey:              #464a4c !default;
$grey-light:        #636c72 !default;
$grey-lighter:      #eceeef !default;
$grey-lightest:     #f7f7f9 !default;
$white:             #fff !default;





//
// Layout
//

$container-max-width: 1230px;
$post-content-width: 980px;
$border-radius: 5px;


// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
) !default;


// Spacers
$spacer: 1rem !default;


//
// Misc
//

$date-ordinals-enabled: false; // Turn off 'st', 'nd', 'rd' from dates


//
// Transitions
//



//
// Logo
//

$logo: 'logo.svg';
$logo-width: 300px;
$logo-height: 120px;


//
// Typography
//

$font-family-base: 'Muli', Arial, sans-serif;

$font-weight-normal: normal;
$font-weight-bold: 800;

$font-size-h1: 2.5em !default;
$font-size-h2: 2em !default;
$font-size-h3: 1.75em !default;
$font-size-h4: 1.5em !default;
$font-size-h5: 1.25em !default;
$font-size-h6: 1em !default;

$headings-margin-top: 1.5em !default; // This will only affect headings within post content


// Links

// Icon font


//
// Buttons
//

$btn-padding-x: 1.5em !default;
$btn-padding-y: .6em !default;
$btn-font-weight: $font-weight-bold;

// Donate button

//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//

// Social network colours - use these by changing $social-icons-use-brand-colours to true
$social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-colour: $brand-primary; // A colour, or 'brand'
$social-icons-hover-colour: darken($brand-primary, 10%);
$social-icons-font-size: 1.5rem;

// Header specific

// Footer specific social icons


//
// Share this page
//



//
// Form elements
//

$input-padding-y: .375rem !default;
$input-padding-x: .75rem !default;


// Field groups


//
// Tables
//



//
// Card
//

$card-border: 0;
$card-summary-enabled: false;
$card-footer-background-colour: transparent;

// Card text overlay

// Card text over

// Card side by side

// Card hover state
$card-hover-image-opacity: .7 !default;

// Event cards
$event-card-floating-date: true;

//
// Menu admin
//
$menu-admin-enabled: false;


//
// Header
//
$header-background-colour: $_lightblue;


// Header content
$header-content-margin-top: 0;
$header-content-padding-top: $spacer * 0.5;
$header-content-margin-bottom: 0;
$header-content-padding-bottom: $spacer * 0.5;

// Tagline
$tagline-enabled: true;
$tagline-above-cta-breakpoint: 2600px;
$tagline-breakpoint: 1px;
$tagline-font-size: $font-size-h3;
$tagline-font-weight: normal;

// Main call to action

// Search

// Search - input
$header-search-input-margin-right: -43px;
$header-search-input-padding: $input-padding-y 43px $input-padding-y $input-padding-x;

// Search - button
$header-search-button-background-colour: transparent;
$header-search-button-icon-colour: $grey;

// Social icons

// Colours set above (search for '$social-icons-header')


//
// Sticky header
//


//
// Navigation
//
$nav-background-colour: tint($_lightblue, 50%);


// Top level items
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-hover-colour: darken($brand-primary, 10%);
$nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-item-font-size: 1.125em;
$nav-top-level-chevrons-enabled: true;

// Submenus
$nav-submenu-background-colour: white;
$nav-submenu-box-shadow: 0 20px 20px rgba(black, 0.05);
$nav-submenu-item-font-weight: $font-weight-bold;
// Burger button
$burger-btn-bar-breakpoint: map-get($breakpoints, sm); // What viewport width do you want the burger button to pop under the header full width?

// Nav normal
$nav-normal-margin-bottom: 0;
$nav-normal-align-items: center; // left, center, right
$nav-normal-max-width: 100%; // $container-max-width
$nav-normal-mobile-background-colour: white;
$nav-normal-border-radius: 0;

//
// Specifically when no banner present and you have gone for $header-absolute: true
//



//
// Carousel
//
$carousel-max-width: 100%;
$card-heading-font-size: $font-size-h4;
$carousel-contents-max-width: $container-max-width; // How wide do you want .carouselDetailWrapper?
$carousel-image-overlay: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 23.21%, rgba(0, 0, 0, 0.1) 46.48%, rgba(0, 0, 0, 0) 70%); // Add a colour value or gradient here
$carousel-details-background-colour: transparent;
$carousel-details-padding: 0;
$carousel-details-position-y: bottom; // top, center, bottom
$carousel-details-position-x: center; // left, center, right
$carousel-heading-colour: white;
$carousel-summary-colour: white;
$carousel-summary-enabled: false;
$carousel-heading-font-size: $font-size-h1;
$carousel-details-max-width: 500px;
$carousel-border-radius: 0;
$carousel-details-margin-y: $spacer * 7.5;
$carousel-details-text-align: center;


// Carousel controls (left-right buttons)
$carousel-controls-button-background-colour: transparent;
$carousel-controls-position-y: center; // top, center, bottom
$carousel-controls-position-x: split; // split, left or right
$carousel-controls-margin-x: $spacer;
$carousel-controls-icon-font-size: 1.5em;

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint
$carousel-mobile-details-background-colour: white;

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//
$home-intro-background-colour: $_lightblue;
$home-intro-margin-top: 0;
$home-intro-background-image: 'home-intro.svg';
$home-intro-padding-y: $spacer * 4;



//
// Home features
//
$home-features-padding-top: 0;
$home-features-padding-bottom: 0;
$home-features-cards-entry-animation-prefab: 1; // See https://fresco-docs.netlify.com/#/cards/entry-animations
$home-features-background-colour: transparent;



//
// Impact stats
//
// $impact-stats-background-image: 'home-intro.svg';
$impact-stats-background-image-opacity: 0.2;
$impact-stats-background-colour: white;


// Heading
$impact-stats-heading-margin-bottom: $spacer * 1;

// Individual stat

// Figure
$impact-stats-figure-colour: $_lightblue;
$impact-stats-figure-font-size: 5em;

// Summary


//
// Home feeds
//
$home-feeds-cards-entry-animation-prefab: 1; // See https://fresco-docs.netlify.com/#/cards/entry-animations


// Feeds title

// Feed item

// Event feed items
$feed-item-event-location-enabled: false;

// Twitter feed


//
// Footer
//

$footer-background-colour: $_lightblue;
$footer-headings-font-size: $font-size-h5;
$footer-link-decoration: none;
$footer-link-hover-decoration: none;

// Footer admin links

// Newsletter
$newsletter-background-colour: shade($_lightblue, 90%);

// Newsletter hero - full width form above rest of footer


//
// Context container
//


// Donors list


//
// Posts
//

$post-content-max-width: $post-content-width;


//
// Header text (container for breadcrumb and page title)
//

$header-text-margin-top: $spacer;


//
// Page title
//

$page-title-margin-top: $spacer * 3;


//
// Sidebar
//

$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts

// Associated lists

//
// Listing
//


// Listed post settings


//
// Breadcrumb
//



//
// Blockquote
//



//
// Fundraising
//


// Find a fundraiser widget

// Top fundraisers


//
// Donation form
//


// Donation amounts


//
// Quick giving panel
//
$quick-giving-donation-amount-background-colour: $white;
$quick-giving-donation-amount-figure-colour: $donate-colour;
// $quick-giving-donation-amount-border-width: $btn-border-width !default;
// $quick-giving-donation-amount-border-colour: $donate-colour !default;
$quick-giving-donation-amount-selected-background-colour: $donate-colour;
$quick-giving-donation-amount-selected-figure-colour: $white;


//
// Home quick giving panel
//
$home-quick-giving-type: overlay;
$home-quick-giving-heading-colour: white;
$home-quick-giving-background-gradient: linear-gradient(to right, #000 0%, rgba(0,0,0,0) 70%);
$home-quick-giving-content-max-width: $container-max-width;
$home-quick-giving-donate-btn-background-colour: $donate-colour;
$home-quick-giving-donation-amount-background-colour: $white;
$home-quick-giving-donation-amount-figure-colour: $donate-colour;
$home-quick-giving-donation-amount-border-colour: $donate-colour;
$home-quick-giving-donation-amount-selected-background-colour: $donate-colour;
$home-quick-giving-donation-amount-selected-figure-colour: $white;

// Adjacent homepage quick giving

// Overlay homepage quick giving


//
// Totalisers
//



//
// Comments
//



//
// Blog details
//



//
// FAQs
//



//
// Shop
//

$shop-enabled: true !default; // Quickly disable shop on the platform (hide basket and not include relevant Sass for compilation)

// Departments list

// Product-price

// Product post


//
// Subsite
//


// Subsite logo

// Subsite nav


//
// Cookie consent
//
